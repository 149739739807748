/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable wrap-rem-on-px/wrap-rem-on-px */
/* stylelint-enable wrap-rem-on-px/wrap-rem-on-px */
/*
USAGE:
font-size: fluid(12, 62); : from rem(12)@375px to  rem(62)@1440px
gap: fluid(10, 33, 992);  : from rem(10)@991px to  rem(33)@1440px
margin-right: fluid(32, 65, 320, 2560);  : from rem(32)@320px to  rem(65)@2560px
*/
/* stylelint-disable */
/* stylelint-enable */
/* Font */
/* Base palette */
/* Brand palette */
/* Theme */
/* State palette */
.qpac-error {
  padding: 0 0 10.1875rem;
  text-align: center;
}
.qpac-error h2 {
  color: #00FFAE;
  font-size: clamp(7.5rem, 16.91729vw + 3.52443625rem, 18.75rem);
  line-height: 1;
  text-transform: uppercase;
}
.qpac-error p {
  margin-bottom: clamp(1.5rem, 1.50376vw + 1.14661625rem, 2.5rem);
  text-transform: uppercase;
}